import React, { useEffect } from "react";

const   StripePricingTable = () => {
useEffect(() => {
const script = document.createElement("script");
script.src = "https://js.stripe.com/v3/pricing-table.js";
script.async = true;
document.body.appendChild(script);
return () => {
document.body.removeChild(script);
};
}, []);
return React.createElement("stripe-pricing-table", {
"pricing-table-id": "prctbl_1NMTeWFzfGpcJmlkAyKAuquj",
"publishable-key": "pk_live_51NEb7YFzfGpcJmlklaBTLon5W9NHjCUApDFZbLeAe3YRbBoY3L74BbLLBbmUd2hF7eQBZZl87zdwxfInwro1pSAO00qu86dv5t",
});
};
export default StripePricingTable;