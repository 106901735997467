import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Button } from '@mui/material'; 

import Home from './components/Home';
import AdminPanel from './components/AdminPanel';
import LiveChat from './components/LiveChat';
import Metrics from './components/Metrics';

// Configure Firebase.
// need to define it here again b/c compatibility issues with firebaseui and firebase v9
const firebaseConfig = {
  apiKey: "AIzaSyAJ3ikEVFp5yxmfCjXVmJX2LDM0o-um-yA",
  authDomain: "emmachat-81731.firebaseapp.com",
  projectId: "emmachat-81731",
  storageBucket: "emmachat-81731.appspot.com",
  messagingSenderId: "440212327743",
  appId: "1:440212327743:web:4da27a8d64790e8d4dd78a",
  measurementId: "G-GLTWF0XF6S",
};
firebase.initializeApp(firebaseConfig);

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid Navigates after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

export default function App() {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element ={
          <React.Fragment>
            {!isSignedIn ? (
              <>
                <p>Please sign in:</p>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
              </>
            ) : firebase.auth().currentUser.uid === 'ddrpCmmSTihA16CETZxe6J33v8M2' ? (
              <AdminPanel />
            ) : (
            <Button variant="contained" color="secondary" onClick={() => firebase.auth().signOut()}>
              Sign Out
            </Button>
            )}
          </React.Fragment>
          
        } />
        <Route path="/admin/livechat" element={
          <React.Fragment>
          {!isSignedIn ? (
            <>
              <p>Please sign in:</p>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </>
          ) : firebase.auth().currentUser.uid === 'ddrpCmmSTihA16CETZxe6J33v8M2' ? (
            <>
              <AdminPanel />
              <LiveChat />
            </>
          ) : (
            <Navigate to="/" replace={true} /> // Redirect to the main page if not authorized
          )}
        </React.Fragment>
        } />
        <Route path="/admin/metrics" element={
          <React.Fragment>
          {!isSignedIn ? (
            <>
              <p>Please sign in:</p>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </>
          ) : firebase.auth().currentUser.uid === 'ddrpCmmSTihA16CETZxe6J33v8M2' ? (
            <>
              <AdminPanel />
              <Metrics />
            </>
          ) : (
            <Navigate to="/" replace={true} /> // Redirect to the main page if not authorized
          )}
        </React.Fragment>
        } />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);