import React from "react";
import ReactDOM from "react-dom/client";

import Histogram from './Histogram';

function Metrics() {
  return (
    <>
        <Histogram />
    </>
  );
}

export default Metrics;