import React, { useEffect, useRef, useState } from "react";
import {
    query,
    collection,
    orderBy,
    onSnapshot,
    limit,
  } from "firebase/firestore";
import { db } from "../firebase";
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';

function generateUserSignupData(users) {
    // Create an object to store the count of users for each signup date
    const userCounts = {};
  
    // Iterate over the users and count the number of users for each signup date
    users.forEach(user => {
      const dateObj = new Date(user.signup_date.seconds * 1000);
      const signupDate = dateObj.toLocaleDateString();
    //   const signupDate = user.signup_date.toDateString(); // Convert the signup date to a string representation (e.g., "Mon Aug 02 2023")
  
      // If the signup date is already present in userCounts, increment the count; otherwise, initialize it to 1
      if (userCounts.hasOwnProperty(signupDate)) {
        userCounts[signupDate]++;
      } else {
        userCounts[signupDate] = 1;
      }
    });

    // Sort the dates in ascending order
    const sortedDates = Object.keys(userCounts).sort((a, b) => new Date(a) - new Date(b));
  
    // Calculate the cumulative user counts
    let cumulativeCount = 0;
    const counts = sortedDates.map(date => {
        cumulativeCount += userCounts[date];
        return cumulativeCount;
    });

    return { dates: sortedDates, counts };
  }
  

function Histogram({  }) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "users")); // Replace "users" with the actual collection name
      
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const fetchedUsers = [];
          querySnapshot.forEach((doc) => {
            fetchedUsers.push({ ...doc.data()});
          });

          const { dates, counts } = generateUserSignupData(fetchedUsers);
            const data = {
                dates: dates,
                counts: counts,
            };
          setUsers(data);
        });
      
        return () => unsubscribe();
      }, []);


    const chartData = {
      labels: users.dates, // Replace with your own labels
      datasets: [
        {
          label: 'Histogram',
          data: users.counts,
          backgroundColor: 'rgba(75, 192, 192, 0.6)', // Adjust the color as desired
        },
      ],
    };
  
    return (
      <div>
        <Line data={chartData} />
      </div>
    );
  }
  
export default Histogram;