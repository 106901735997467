// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import 'firebase/functions';

import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

const firebaseConfig = {
    apiKey: "AIzaSyAJ3ikEVFp5yxmfCjXVmJX2LDM0o-um-yA",
    authDomain: "emmachat-81731.firebaseapp.com",
    projectId: "emmachat-81731",
    storageBucket: "emmachat-81731.appspot.com",
    messagingSenderId: "440212327743",
    appId: "1:440212327743:web:4da27a8d64790e8d4dd78a",
    measurementId: "G-GLTWF0XF6S",
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export { db };

// Initialize the FirebaseUI Widget using Firebase.
