import React from "react";

const Message = ({ user_name, text, time_of_message, stickerUrl }) => {

  const dateObj = new Date(time_of_message.seconds * 1000);
  const dateString = dateObj.toLocaleString();

  return (
    <>
      <div className={user_name === "Emma" ? "chat-bubble__left" : "chat-bubble"}>
        <div className={`chat-info`}>
          <p className={`chat-username`}>{user_name}</p>
          <p className={`chat-time`}>{dateString}</p>
        </div>
        <p className={`chat-message`}>{text}</p>
        {stickerUrl && <img className={'chat-image'} src={stickerUrl} alt="sticker"/>}
      </div>
    </>
  );
};

export default Message;