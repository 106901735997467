import React from "react";
import { Link } from 'react-router-dom';
import { Button } from '@mui/material'; 

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

function AdminPanel() {
  const containerStyles = {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '16px 16px 16px 16px',
  };
  const buttonStyles = {
    margin: '8px 8px 8px 8px',
  };

  return (
    <>
      <div style={containerStyles}>
        {/* Links to livechat and metrics */}
        <Link to="/admin/livechat">
          <Button variant="contained" color="primary"style={buttonStyles} >Live Chat</Button>
        </Link>
        <Link to="/admin/metrics">
          <Button variant="contained" color="primary" style={buttonStyles}>Metrics</Button>
        </Link>

        {/* Sign out button */}
        <Button variant="contained" color="secondary" style={buttonStyles} onClick={() => firebase.auth().signOut()}>
          Sign Out
        </Button>
      </div>
    </>
  );
}

export default AdminPanel;
