import React from "react";
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./Home.css";

import StripePricingTable from "./StripePricingTable";

// import images
import emma_no_bg from "../img/emma_no_bg.png";
import exampleChat from "../img/exampleChat.jpg";
import WhatsAppButtonGreenLarge from "../img/WhatsAppButtonGreenLarge.svg";

function Home() {

  return (
    <>
        
        {/* <!-- Introduction Section --> */}
        <div className="section-1 py-5">
            <div className="container my-5">
                <div className="row align-items-center">
                    <div className="col-sm">
                        <h2 className="mb-3"><img className="emma-picture" src={emma_no_bg} alt="" width="100" height="100"></img>Hi! I'm Emma 😃👋</h2>                    
                        <p className="mb-4">I'm your go-to AI for all things WhatsApp stickers! 🎉💫 So, ready to spice up your chats with some awesome stickers? 🚀 OMG, we're gonna have a blast! 💥🌈 Unleash your sticker game with <b>10 free stickers</b> when you start right now! 🎁🤩🥳</p>
                        <a aria-label="Chat on WhatsApp" href="https://wa.me/31657517344?text=A%20funny%20picture%20of%20a%20cat%20on%20a%20bicycle"><img   className="chat-link" alt="Chat on WhatsApp" src={WhatsAppButtonGreenLarge}/></a>
                    </div>
                    <div className="col-sm">
                        <img id="example-chat-picture" src={exampleChat} alt=""></img>
                    </div>                
                </div>
            </div>
        </div>
        <div className="section-2 py-5">
            <div className="container my-5">
                <div className="col-sm">
                    <StripePricingTable />
                </div>                
            </div>
        </div>
    
        {/* <!-- FAQs Section --> */}
        <div className="section-3 py-5">
        <div className="container my-5">
            <h2 className="text-center mb-4">Frequently Asked Questions</h2>
            <div className="accordion" id="faqAccordion">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                >
                    How do I chat with Emma?
                </button>
                </h2>
                <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#faqAccordion"
                >
                <div className="accordion-body">
                    Simply tap on the "Chat on WhatsApp" button above to open WhatsApp and start chatting with me, Emma! 📲💬 You can also reach me directly at my phone number: +31 (0)6 57517344. Let's connect and bring your sticker game to life! 🎉🎈
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                >
                    How many free stickers do I have?
                </button>
                </h2>
                <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#faqAccordion"
                >
                <div className="accordion-body">
                    You've got 10 amazing stickers ready to go, absolutely free! 🎁🤩 After that, each sticker can be prepaid for just 0.05 EUR per sticker. So, you can keep spreading sticker joy without breaking the bank! 💰✨
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                >
                    How can I share Emma's stickers with my friends?
                </button>
                </h2>
                <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#faqAccordion"
                >
                <div className="accordion-body">
                    It's super easy to share my awesome stickers with your friends! Just tap on a sticker you like, save it to your favorites, and you can then access it from any chat. Simply choose the sticker from your favorites, send it, and watch your conversations light up with sticker goodness! 🌟📲💃
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

    
        {/* <!-- Help Form Section --> */}
        <div className="section-4 py-5">
            <div className="container my-5">
            <h2 className="text-center mb-4">Need Help? Contact Me</h2>
            <div className="row">
                <div className="col-md-8 mx-auto">
                    <form id="helpForm">
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">Phone Number</label>
                            <input type="phone" className="form-control" id="phone"></input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea className="form-control" id="message" rows="3"></textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="mood" className="form-label">Mood</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="mood" id="happy" value="Happy"></input>
                                <label className="form-check-label" htmlFor="happy">😄</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="mood" id="sad" value="Sad"></input>
                                <label className="form-check-label" htmlFor="sad">😢</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="mood" id="angry" value="Angry"></input>
                                <label className="form-check-label" htmlFor="angry">😠</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="mood" id="confused" value="Confused"></input>
                                <label className="form-check-label" htmlFor="confused">😕</label>
                            </div>
                        </div>              
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
            </div>
        </div>
        <Helmet>
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.min.js"></script>        
        </Helmet>
    </>
  );
}

export default Home;