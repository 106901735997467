import React, { useState, useEffect } from "react";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";

import "./LiveChat.css";

import ChatBox from "./ChatBox";

function LiveChat() {
  const [selectedUser, setSelectedUser] = useState('');
  const [allUsers, setAllUsers] = useState([]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    // fetch all users from database
    const q = query(
      collection(db, "users"),
    );
    
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedUsers = [];
      QuerySnapshot.forEach((doc) => {
        const data = {
          ...doc.data(),
          phone_number: doc.id, // TODO: format phone number to be more readable
          id: doc.id
        }

        fetchedUsers.push({ ...data });
      });
      setAllUsers(fetchedUsers);
    });
    return () => unsubscribe;
  }, []);

  return (
    <div className="livechat">
      <div className="user-list">
        <div className="user-list-header">
        </div>
        <div className ="user-list-search">
            <input className="user-list-search-box" type="text" placeholder="Search for a user"></input>
        </div>
        <div className="user-list-body">
          <div className={`user-list-item`} key="all_users" onClick={() => handleUserSelect("")}>
            <p className="phone-number">All users</p>
            <p className="user-info">...</p>
          </div>
          {allUsers.map((user) => (
            <div className={`user-list-item ${user.phone_number === selectedUser && "selected-user"}`} key={user.id} onClick={() => handleUserSelect(user)}>
              <p className="phone-number">{user.user_name !== undefined ? user.user_name : user.phone_number}</p>
              <p className="user-info">{user.query_count} messages<br></br> ${user.balance.toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>
      <ChatBox user={selectedUser}/>      
    </div>
  );
}

export default LiveChat;