import React, { useEffect, useRef, useState } from "react";
import { TextField, Button, Switch, FormControlLabel } from '@mui/material';

import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";


import { db } from "../firebase";
import Message from "./Message";
// import SendMessage from "./SendMessage";

import "./LiveChat.css";

// Function to upload the document to Firestore
const uploadMessage = async (phone_number, message) => {
  try {
    await addDoc(collection(db, 'admin_messages'), {
      phone_number: phone_number,
      text: message,
      time_of_message: new Date(), //serverTimestamp() TODO
    });
    console.log('Message uploaded successfully');
  } catch (error) {
    console.error('Error uploading message:', error);
  }
};

const ChatBox = ({ user }) => {
  const [messages, setMessages] = useState([]);
  const [adminMessages, setAdminMessages] = useState([]);
  const [normalMessages, setNormalMessages] = useState([]);
  const scroll = useRef();

  const [inputText, setInputText] = useState('');
  const [isChatbotOn, setIsChatbotOn] = useState(true);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleButtonClick = async () => {
    // Perform action when the button is clicked, e.g., send text
    try {
      // Call cloud function to send text
      console.log('Sending text:', inputText)
      await uploadMessage(user.id, inputText);
      // TODO: add message to firestore so that it can be displayed in the chat window

      setInputText('');
    } catch (error) {
      console.log(error);
    }

    console.log('Sending text:', inputText);
  };

  const handleSwitchChange = (event) => {
    // setIsChatbotOn(event.target.checked);
  };

  useEffect(() => {
    // Fetch admin messages from the admin messages database
    // and update the adminMessages state
    const adminMessagesQuery = query(
      collection(db, "admin_messages"),
      orderBy("time_of_message", "desc"),
      limit(1000)
    );

    const unsubscribeAdminMessages = onSnapshot(adminMessagesQuery, (adminQuerySnapshot) => {
      const fetchedAdminMessages = [];

      adminQuerySnapshot.forEach((doc) => {
        fetchedAdminMessages.push({
          phone_number: doc.data().phone_number,
          user_name: 'Emma',
          text: doc.data().text,
          time_of_message: doc.data().time_of_message,
          id: doc.id,
        });
      });

      setAdminMessages(fetchedAdminMessages);
    });

    return () => {
      unsubscribeAdminMessages(); // Unsubscribe from adminMessagesQuery listener
    };
  }, []);

  useEffect(() => {
    // Fetch normal messages from the normal messages database
    // and update the normalMessages state
    const messagesQuery = query(
      collection(db, "messages"),
      orderBy("time_of_request", "desc"),
      limit(1000)
    );

    const unsubscribeMessages = onSnapshot(messagesQuery, (querySnapshot) => {
      const fetchedMessages = [];
      querySnapshot.forEach((doc) => {
        // Add chatbot's response to fetchedMessages
        if (doc.data().response) {
          fetchedMessages.push({
            phone_number: doc.data().phone_number,
            user_name: 'Emma',
            text: doc.data().response,
            time_of_message: doc.data().time_of_response,
            stickerUrl: doc.data().publicUrl,
            id: doc.id,
          });
        }

        // Add user's message to fetchedMessages
        fetchedMessages.push({
          phone_number: doc.data().phone_number,
          user_name: doc.data().user_name,
          text: doc.data().message ? doc.data().message : "[image]",
          time_of_message: doc.data().time_of_request,
          id: doc.id + '_emma',
        });
      });

      setNormalMessages(fetchedMessages);
    });

    return () => {
      unsubscribeMessages(); // Unsubscribe from messagesQuery listener
    };
  }, []);

  const chatWindowRef = useRef(null);

  const handleChatWindowLoad = () => {
    // Scroll to the bottom of the chat window
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  };

  useEffect(() => {
    // Combine admin and normal messages into a single list
    const combined = [...adminMessages, ...normalMessages];

    // Sort messages based on time_of_message
    combined.sort((a, b) => a.time_of_message - b.time_of_message);

    setMessages(combined);
  }, [adminMessages, normalMessages]);

  return (
    <div className="user-chat-window">
      {/* when a new message enters the chat, the screen scrolls down to the scroll div */}
      {/* <span ref={scroll}></span> */}
      {/* <SendMessage scroll={scroll} /> */}

      <div className="active-user-info">
        {user.user_name !== undefined ? user.user_name : user.id}
      </div>

      <div className="chats-window" ref={chatWindowRef} onLoad={handleChatWindowLoad}>
        {messages
          ?.filter(message => user !== "" ? message.phone_number === user.id : true)
          .map((message) => (
            <Message key={message.id} {...message} />
          ))}
      </div>
      <div className="send-chat-window">
        <TextField
          className="input-field"
          label="Text"
          variant="filled"
          value={inputText}
          onChange={handleInputChange}
        />
        <div className="button-group">
          <Button
            className="send-button"
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            Send
          </Button>
          <FormControlLabel
            control={
              <Switch
                className="chatbot-switch"
                checked={isChatbotOn}
                onChange={handleSwitchChange}
                color="primary"
              />
            }
            label="Chatbot"
          />
        </div>
      </div>
      <div id="map style={{ width: '400px', height: '400px' }}"></div>
      <script src="https://rotterdam-explosie.web.app/bundle.js"></script>
    </div>
  );
};

export default ChatBox;